.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 25vh;
  width: 100vw;
  background-color: #8fbfe0;
}

.titleLetters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #247ba0;
  height: 20vh;
  width: 10vw;
  font-size: calc(15vmin);
  font-family: "Chelsea Market";
  color: #e6efe9;
  text-align: center;
}
