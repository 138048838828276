.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #006daa;
}

.pageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.timer {
  height: 25vh;
  width: 25vh;
}

.audio {
  height: 0px;
}

.letterTiles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
  width: 80vw;
}

.letterRoundTiles {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c97c5;
  height: 15vh;
  width: 8vw;
}

.letterRoundLetters {
  text-align: center;
  font-size: calc(12vh);
  font-family: "Chelsea Market", serif;
  color: white;
}

.letterRoundButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
  width: 75vw;
}

.letterRoundButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 15vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", serif;
  text-align: center;
  color: white;
  opacity: 1;
  transition: 0.3s;
}

.letterRoundButtons:hover {
  opacity: 0.8;
}

.letterRoundLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 15vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", serif;
  text-align: center;
  color: white;
  text-decoration: none;
  opacity: 1;
  transition: 0.3s;
}

.letterRoundLink:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  /*this is for phones*/
  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
  }

  .letterTiles {
    height: 20vh;
    width: 98vw;
  }

  .letterRoundTiles {
    height: 7vh;
    width: 10vw;
  }

  .letterRoundLetters {
    font-size: calc(6vh);
  }

  .letterRoundButtonRow {
    height: 20vh;
    width: 80vw;
    flex-wrap: wrap;
  }

  .letterRoundButtons {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }

  .letterRoundLink {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* this is for small ipads */
  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
  }

  .letterTiles {
    height: 20vh;
    width: 98vw;
  }

  .letterRoundTiles {
    height: 8vh;
    width: 10vw;
  }

  .letterRoundLetters {
    font-size: calc(6vh);
  }

  .letterRoundButtonRow {
    height: 10vh;
    width: 90vw;
  }

  .letterRoundButtons {
    height: 8vh;
    width: 20vw;
    font-size: calc(3vw);
  }

  .letterRoundLink {
    height: 8vh;
    width: 20vw;
    font-size: calc(3vw);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* this is for large ipads*/
  .audio {
    height: 0vh;
  }

  .letterTiles {
    height: 15vh;
  }

  .letterRoundButtonRow {
    height: 20vh;
    width: 80vw;
    flex-wrap: wrap;
  }

  .letterRoundButtons {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }

  .letterRoundLink {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }
}
