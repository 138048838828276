* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

@font-face {
  font-family: "Chelsea Market";
  src: url(./fonts/ChelseaMarket-Regular.ttf) format("truetype");
}

body {
  min-height: 100%;
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: #e6efe9;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
