.clockFace {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.circleOuter {
  z-index: 1;
  height: 100%;
  width: 100%;
  border: 2px solid #0a81d1;
  border-radius: 50%;
  background-color: #efe9e7;
  opacity: 0.8;
  position: absolute;
}

.secondHand {
  z-index: 2;
  height: 0px;
  width: 0px;
  border-color: #0a81d1 transparent;
  border-style: solid;
  border-width: 0px 4px 60px 4px;
  transform-origin: bottom center;
  position: absolute;
  top: 15%;
}

.centre {
  z-index: 3;
  height: 15%;
  width: 15%;
  background-color: #0a81d1;
  border-radius: 50%;
  position: absolute;
}
