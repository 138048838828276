.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #006daa;
}

.pageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.timer {
  height: 25vh;
  width: 25vh;
}

.conundrumLetterTiles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
  width: 80vw;
}

.conundrumTiles {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c97c5;
  height: 15vh;
  width: 8vw;
  text-align: center;
  font-size: calc(12vh);
  font-family: "Chelsea Market", serif;
  color: white;
}

.definition {
  font-size: calc(2vh);
  font-family: "Chelsea Market", serif;
  color: white;
  text-align: center;
  font-style: oblique;
  width: 80vw;
  height: 15vh;
}

.conundrumButtonSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
  width: 75vw;
}

.conundrumButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 15vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", serif;
  text-align: center;
  color: white;
  opacity: 1;
  transition: 0.3s;
}

.conundrumButtons:hover {
  opacity: 0.8;
}

.conundrumLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 15vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", serif;
  text-align: center;
  color: white;
  text-decoration: none;
  opacity: 1;
  transition: 0.3s;
}

.conundrumLink:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  /*this is for phones*/

  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
  }

  .conundrumLetterTiles {
    height: 10vh;
    width: 90vw;
  }

  .conundrumTiles {
    height: 5vh;
    width: 9vw;
    font-size: calc(3vh);
  }

  .conundrumButtonSection {
    height: 15vh;
    width: 90vw;
  }

  .conundrumButtons {
    width: 26vw;
    font-size: calc(4vw);
  }

  .conundrumLink {
    width: 26vw;
    font-size: calc(4vw);
  }

  .definition {
    height: 12vh;
    font-size: calc(1.5vh);
    /* overflow: scroll; */
  }
}

@media (min-width: 481) and (max-width: 770px) {
  /* this is for small ipads */

  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
    width: 0vw;
  }

  .conundrumLetterTiles {
    height: 20vh;
    width: 90vw;
  }

  .conundrumTiles {
    height: 8vh;
    width: 9vw;
    font-size: calc(3vh);
  }

  .conundrumButtonSection {
    height: 15vh;
    width: 90vw;
  }

  .conundrumButtons {
    width: 26vw;
    font-size: calc(4vw);
  }

  .conundrumLink {
    width: 26vw;
    font-size: calc(4vw);
  }

  .definition {
    height: 10vh;
    font-size: calc(2vh);
    /* overflow: scroll; */
  }
}

@media (min-width: 771px) and (max-width: 1024px) {
  /* this is for large ipads */

  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
    width: 0vw;
  }

  .conundrumLetterTiles {
    height: 20vh;
    width: 90vw;
  }

  .conundrumTiles {
    height: 8vh;
    width: 9vw;
    font-size: calc(3vh);
  }

  .conundrumButtonSection {
    height: 15vh;
    width: 90vw;
  }

  .conundrumButtons {
    width: 26vw;
    font-size: calc(4vw);
  }

  .conundrumLink {
    width: 26vw;
    font-size: calc(4vw);
  }

  .definition {
    height: 10vh;
    font-size: calc(2vh);
    /* overflow: scroll; */
  }
}
