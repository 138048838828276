.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #006daa;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 30vh;
  width: 100vw;
  background-color: #8fbfe0;
}

.titleLetters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #247ba0;
  height: 20vh;
  width: 10vw;
  font-size: calc(15vmin);
  font-family: "Chelsea Market";
  color: #e6efe9;
  text-align: center;
}

.pageBody {
  display: flex;
  flex-direction: column;
  justify-content: centre;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.buttonColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 75vh;
  width: 75vw;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 25vw;
  background-color: #247ba0;
  border: 1px solid white;
  border-radius: 2px;
  font-size: calc(5vmin);
  font-family: "Chelsea Market", "open sans", serif;
  text-align: center;
  color: #e6efe9;
  text-decoration: none;
  opacity: 1;
  transition: 0.3s;
}

.link:hover {
  opacity: 0.8;
}

@media (max-width: 480px) {
  /*this is for phones*/

  .header {
    height: 12vh;
  }

  .titleLetters {
    height: 8vh;
    width: 10vw;
    font-size: calc(10vmin);
  }

  .link {
    height: 9vh;
    width: 80vw;
    font-size: calc(8vmin);
  }
}

@media (max-width: 770px) {
  /* this is for small ipads */

  .header {
    height: 10vh;
  }

  .titleLetters {
    height: 8vh;
    width: 10vw;
    font-size: calc(10vmin);
  }

  .link {
    height: 10vh;
    width: 75vw;
    font-size: calc(8vmin);
  }
}

@media (max-width: 1024px) {
  /* this is for large ipads */

  .header {
    height: 10vh;
  }

  .titleLetters {
    height: 8vh;
    width: 10vw;
    font-size: calc(10vmin);
  }

  .link {
    height: 10vh;
    width: 65vw;
    font-size: calc(6vmin);
  }
}
