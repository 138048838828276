.background {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #006daa;
}

.pageBody {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.timer {
  height: 25vh;
  width: 25vh;
}

.numberTiles {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 15vh;
  width: 60vw;
}

.tiles {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c97c5;
  border: none;
  height: 15vh;
  width: 8vw;
  text-align: center;
  font-size: calc(9vh);
  font-family: "Chelsea Market", serif;
  color: white;
}

.numberRoundButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
  width: 50vw;
}

.numberRoundButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 10vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", serif;
  text-align: center;
  color: white;
  opacity: 1;
  transition: 0.3s;
}

.numberRoundButtons:hover {
  opacity: 0.8;
}

.numberRoundLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 10vw;
  background-color: #2c97c5;
  border: none;
  font-size: calc(2vw);
  font-family: "Chelsea Market", "open sans", serif;
  text-align: center;
  color: white;
  text-decoration: none;
  opacity: 1;
  transition: 0.3s;
}

.numberRoundLink:hover {
  opacity: 0.8;
}

.targetSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15vh;
  width: 10vw;
}

@media (max-width: 480px) {
  /*this is for phones*/
  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
  }

  .numberTiles {
    height: 20vh;
    width: 98vw;
  }

  .tiles {
    height: 8vh;
    width: 14vw;
    font-size: calc(5vh);
  }

  .numberRoundButtonRow {
    height: 20vh;
    width: 80vw;
    flex-wrap: wrap;
  }

  .numberRoundButtons {
    height: 8vh;
    width: 35vw;
    font-size: calc(6vw);
    margin-bottom: 2vh;
  }

  .numberRoundLink {
    height: 8vh;
    width: 35vw;
    font-size: calc(6vw);
    margin-bottom: 2vh;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* this is for small ipads */
  .pageBody {
    justify-content: center;
  }

  .audio {
    height: 5vh;
  }

  .numberTiles {
    height: 20vh;
    width: 98vw;
  }

  .tiles {
    height: 10vh;
    width: 14vw;
    font-size: calc(6vh);
  }

  .numberRoundButtonRow {
    height: 10vh;
    width: 90vw;
  }

  .numberRoundButtons {
    height: 8vh;
    width: 20vw;
    font-size: calc(3vw);
  }

  .numberRoundLink {
    height: 8vh;
    width: 20vw;
    font-size: calc(3vw);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* this is for large ipads*/
  .audio {
    height: 0vh;
  }

  .numberTiles {
    height: 20vh;
  }

  .numberRoundButtonRow {
    height: 20vh;
    width: 80vw;
    flex-wrap: wrap;
  }

  .numberRoundButtons {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }

  .numberRoundLink {
    height: 8vh;
    width: 35vw;
    font-size: calc(5vw);
    margin-bottom: 2vh;
  }
}
